import './FooterStyles.css'

const Footer =()=>{
    return (
        <div className='footer'>
            <div className='top'>
                <div>
                    <h1>Baddegama Medical Services Limited</h1>
                    <p>Piooners in Digital Health</p>
                </div>
                <div>
                    <a href='https://web.facebook.com/profile.php?id=100070117352734'>
                        <i className='fa-brands fa-facebook-square'>

                        </i>
                    </a>
                    <a href='#'>
                        <i className='fa-brands fa-instagram-square'>

                        </i>
                    </a>
                    <a href='#'>
                        <i className='fa-brands fa-twitter-square'>

                        </i>
                    </a>
                </div>
            </div>
            <div className='bottom'>
                <div>
                    <h4>Location</h4>
                    <p>Opposite the Bus Stand,<br/>
                        Baddegama.<br/>
                    </p>
                </div>
                <div>
                    <h4>Opening Hours</h4>
                    <p>6.30 AM to 22.30 PM<br/>
                    </p>
                </div>
                <div>
                    <h4>Hotline </h4>
                    <p>+94 70 4450 800</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="text">&#169; Copyright 2023. All Rights Reserved. Baddegama Medical Services Limited</div>

        </div>
    );
}

export default Footer