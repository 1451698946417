import './serviceStyle.css'
import ReactPlayer from 'react-player';

function ServiceV(props){
    

    return(
        <div className="container">
            <h1 className='topic'>{props.name}</h1>
            <div className="content">
            <iframe
                className='image'
                src={props.url}
                allow="autoplay; "
            ></iframe>
            <p className="text">{props.content}</p>
            </div>
      </div>
    );
}


export default ServiceV;