export const MenuItems=[
    {
        title:"Home",
        url:'/',
        cName:'nav-links',
        icon:"fa-solid fa-house-user"
    },
    {
        title:"Services",
        url:'/services',
        cName:'nav-links',
        icon:"fa-solid fa-briefcase"
    },
    {
        title:"Doctors",
        url:'/doctors',
        cName:'nav-links',
        icon:"fa-solid fa-user-doctor"
    },
    {
        title:"About Us",
        url:'/aboutUs',
        cName:'nav-links',
        icon:"fa-solid fa-info"
    },
    {
        title:"Contact Us",
        url:'/contact',
        cName:'nav-links',
        icon:"fa-solid fa-address-book"
    }
]