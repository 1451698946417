import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Welcome from "../components/Welcome"
import ImageSlider from "../components/ImageSlider"
import DoctorsC from "../components/DoctorsC"
import Footer from "../components/Footer"


function Home() {
    return (
        <>
        <Navbar/>
        <Hero
            cName="hero"
            content="https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=740&t=st=1693023849~exp=1693024449~hmac=08dd101d1312a89dd485d708811ce9194d5731cccf70c5c1e0dabf919f987cac"
            title="Baddegama Medical Services Limited"
            para="Pioneers in Digital Health"
        />
        <Welcome/>
        <ImageSlider/>
        <DoctorsC/>
        <Footer/>
        </>
    )
}

export default Home