import './DoctorDetailsStyle.css'
import DoctorData from './DoctorData';
import Doctor1 from '../assets/doctor.png'


function DoctorsDetails(){
    return(
        <div className='doctors'>
            <p>Where Compassion Meets Expertise: Healing Hands, Caring Hearts.</p>
            <div className='doctor-card'>
                <DoctorData
                    image={Doctor1}
                    name="Prof H.M.M. Herath"
                    qualification="MBBS(Ruhuna), MD(Col), MRCP(UK), FRCP(Edin), FCCP"
                    data="Consultant Physician and Professor in Medicine"
                />
                <DoctorData
                    image={Doctor1}
                    name="Prof Niroshana Dahanayake"
                    qualification="MBBS, MD, FCCP"
                    data="Consultant Physician and Professor in Medicine, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Warsha De Zoysa"
                    qualification="MBBS, MD(Medicine)"
                    data="Consultant Physician and Senior Lecturer in Medicine,Teaching Hosptial Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Ranjith Jayasinghe"
                    qualification="MBBS, MD, FCCP"
                    data="Consultant Physician and Teaching Hosptial Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Dhammika Palangasinghe"
                    qualification="MBBS, MD (Medicine)"
                    data="Consultant Physician and Lecturer in Medicine, Teaching Hospital Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Chaminda Kottage"
                    qualification="MBBS, MD(Medicine)"
                    data="Consultant Physician, Teaching Hospital Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Wasantha Kodikara Arachchi"
                    qualification="MD, MRCP, (UK),DTM and H, "
                    data="Consultant Physician"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Upul De Silva"
                    qualification="MBBS, MD (Medicine)"
                    data="Consultant Physician, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Jagath Dandeniya"
                    qualification="MBBS, MD(Obs & Gyn), MSLCOG"
                    data="Specialist in Obstetrics and Gynaecology, Teaching Hospital-Mahamodara"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Lanka Dassanayake"
                    qualification="MBBS (Ruhuna), MD(Obs and Gyn), MRCOG (Gt Britain) FSLCOG"
                    data="Consultant in Obstetrics and Gynaecology, Teaching Hospital-Mahamodara"
                />
                <DoctorData
                    image={Doctor1}
                    name="Prof S.A.C. Krishan Silva"
                    qualification="MBBS, MD(Obs and Gyn), FSLCOG, FRCOG(UK)"
                    data="Consultant Obstetrician and Gynaecologist, Teaching Hospital-Mahamodara"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Sampath Kariyawasam"
                    qualification="MBBS, MD(Obs and Gyn),MSLCOG, MRCOG(London),Fellowship in Laparoscopy"
                    data="Consultant Obstetrician and Gynaecologist, Base Hospital-Elpitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Ajith Jayasekara"
                    qualification="MBBS, MD (Psychiatry)"
                    data="Consultant Psychiatrist, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Harsha Mendis"
                    qualification="MBBS, NS(Col), FRCS (Edin)"
                    data="Consultant Orthopaedic Surgeon, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Darshana Dharmadasa"
                    qualification="MBBS (SL), MD(Opthalmology)"
                    data="Consultant Eye Surgeon, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Nadeeja Senevirathne"
                    qualification="MBBS (Col), MD(Col)"
                    data="Consultant Cardiologist, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. S. Anuruddha Wickramasinghe"
                    qualification="MBBS, MS(Surgery)"
                    data="Consultant ENT Surgeon, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Aruna De Silva"
                    qualification="MBBS, MD (Col), DCH (Col)"
                    data="Consultant Paediatrician, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Lalitha Dissanayake"
                    qualification="MBBS, MD (Col),"
                    data="Consultant Paediatrician, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Kapila Witharana"
                    qualification="MBBS, MD(Dermatology)"
                    data="Consultant Dermatologis, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. S.P. Dissanayake"
                    qualification="MBBS, MD (Colombo)"
                    data="Consultant Rheumatologist, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Duminda Samarawickrama"
                    qualification="MBBS, MD"
                    data="Consultant Clinical Neurophysician & Clinical Neurophysiologist, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Muditha Weerakkody"
                    qualification="MBBS (Col), MD (Col),MRCP (UK), MRCP Diabetes and Endocrinology (UK) "
                    data="Consultant Endocrinologist, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Yohan Koralage"
                    qualification="MBBS, MD(Col), MRCS(London)"
                    data="Consultan Neurosurgeon, Teaching Hospital-Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Bodhinie De Silva"
                    qualification="MBBS, MD (Dermatology)"
                    data="Consultant Dermatology,Base Hospitial-Elpitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Udari Godage"
                    qualification="MBBS (Col), MD-Medicine (Colombo)"
                    data="Senior Lecturer and Consultant Physician, Faculty of Medicine-University of Ruhuna"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Imalke Kankananarachchi"
                    qualification="MBBS (Col),DCH (Col), MD-Paed (Colombo), DCH (UK),MRCPCH (UK), MRCP (Edin)"
                    data="Consultant Paediatrician, TH Karapitiya, Senior Lecturer in Paediatrics, Faculty of Medicine-University of Ruhuna "
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Hemamali Senevirathne"
                    qualification="MD (Ophth), MBBS, FELWOSHIP & TRAINING IN VR (AUS,USA)"
                    data="Consultant Viteoretinal Surgeon, Consultant Eye Surgeon, Teaching Hospital - Karapitiya"
                />


            </div>
        </div>
    );
}

export default DoctorsDetails