import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import ServiceC from "../components/ServiceC"
import ServiceV from "../components/ServiceV"
import opd from '../assets/OPD.jpg'
import chanelling from '../assets/chanelling.avif'
import lab from '../assets/laboratory.avif'
import physio from '../assets/physio.avif'
import echo from '../assets/echocardio.avif'
import eye from '../assets/eye.jpg'




function Services() {
    return (
        <>
        <Navbar/>
        <Hero
            cName="hero-mid"
            content="https://cdn.pixabay.com/photo/2020/04/19/20/10/test-tube-5065426_1280.jpg"
            title="Services"
        
        />
        <ServiceC
            name="OPD"
            poto={opd}
            content="Welcome to OPD at our medical center, where compassionate care meets cutting-edge medical expertise. Our OPD is designed to provide prompt and efficient healthcare services without the need for an overnight stay. From routine check-ups to specialized consultations, our dedicated team of skilled physicians and healthcare professionals is committed to ensuring your well-being. With a focus on personalized care, our OPD delivers comprehensive medical services, diagnostic assessments, and treatment plans tailored to your unique health needs. We strive to create a welcoming environment where patients receive top-notch medical attention and leave with confidence in their health journey. Your health is our priority, and our OPD is here to serve you with excellence and empathy. "
        />
        <ServiceC
            name="Chanelling"
            poto={chanelling}
            content="At our esteemed medical center, we take pride in offering a seamless Doctor Channeling Facility that ensures optimal healthcare accessibility for our patients. With this innovative service, you can now easily schedule appointments with our highly qualified and experienced medical professionals. Our user-friendly online platform allows you to browse through the profiles of our esteemed doctors, view their availability, and secure your preferred time slot at the click of a button. This streamlined process not only enhances your convenience but also minimizes waiting times, ensuring that you receive timely and personalized medical attention. We understand the value of your time and well-being, and our Doctor Channeling Facility reflects our commitment to providing efficient and patient-centric healthcare services. Trust us to be your dedicated partners on your journey towards optimal health and well-being."
        />
        <ServiceC
            name="Labaratory"
            poto={lab}
            content="Welcome to our Laboratory, where cutting-edge technology meets precision in diagnostics. Committed to delivering unparalleled healthcare, our laboratory is equipped with advanced instrumentation for a comprehensive range of tests, ensuring accurate and timely results. Staffed by a team of highly skilled and experienced professionals, our facility adheres to the highest standards of quality control and safety. From routine blood tests to specialized diagnostics, we prioritize your well-being by providing insights crucial to your health management. At here, we take pride in offering a seamless and efficient laboratory experience, reinforcing our commitment to delivering exceptional healthcare services to our valued patients."
        />
        <ServiceC
            name="Physiotherapy"
            poto={physio}
            content="Welcome to our Physiotherapy Service at our medical center, where we prioritize your well-being through personalized care and cutting-edge rehabilitation services. Our expert team of physiotherapists is dedicated to enhancing your mobility, reducing pain, and improving overall quality of life. With a holistic approach, we offer a range of specialized treatments tailored to address diverse musculoskeletal conditions, sports injuries, and post-surgical rehabilitation. Our facility is equipped with the latest technology and a compassionate staff, ensuring that each patient receives individualized attention and achieves optimal recovery. Whether you're recovering from an injury or seeking proactive measures to maintain a healthy, active lifestyle, our Physiotherapy Facility is committed to empowering you on your journey to wellness."
        />
        <ServiceC
            name="Echocardiography"
            poto={echo}
            content="Welcome to our medical center, where we prioritize your cardiovascular health through our advanced Echocardiography service. Utilizing cutting-edge technology, our expert team of cardiologists employs non-invasive ultrasound imaging to provide a comprehensive assessment of your heart's structure and function. Echocardiography allows us to visualize the heart's chambers, valves, and blood flow patterns, aiding in the early detection and precise diagnosis of various cardiac conditions. With a commitment to excellence and patient well-being, our medical center ensures accurate and timely results, empowering you with the knowledge needed to make informed decisions about your heart health. Trust us to deliver compassionate care and comprehensive Echocardiography services tailored to your unique needs, promoting a heart-healthy future for you and your loved ones."
        />
        <ServiceC
            name="Eye Clinic"
            poto={eye}
            content="Welcome to Eye Clinic at Baddegama Medical Serevices, where we prioritize the vision health and well-being of our patients. Our dedicated team of ophthalmologists and eye care professionals is committed to providing comprehensive and personalized services. From routine eye exams to advanced diagnostic procedures and cutting-edge treatments, we offer a full spectrum of eye care services. Whether you're seeking vision correction, managing eye conditions, or exploring the latest advancements in laser eye surgery, our clinic is equipped with the latest technology to ensure accurate diagnoses and optimal outcomes. At here, we blend expertise with empathy, creating a patient-centric environment that focuses on your individual needs. Trust us to safeguard your vision and guide you towards a future of clear, healthy eyesight."
        />
        <ServiceV
            name="Digital X Ray"
            url="https://drive.google.com/uc?id=12lyXTm-8Y-FL17b7YZe6mJhb3g-1Aj9w"
            content="Welcome to our cutting-edge medical center, where we are proud to offer Digital X-ray services. Our digital X-ray technology represents a significant leap forward in diagnostic imaging, providing our patients with swift and accurate results. With the latest advancements in digital radiography, we ensure high-quality images that aid our skilled medical professionals in promptly diagnosing and devising personalized treatment plans. Say goodbye to traditional X-ray films—our digital system not only reduces radiation exposure but also allows for seamless storage and retrieval of your medical images, fostering a more efficient and paperless healthcare experience. Trust in our commitment to technological excellence and compassionate care as we continue to elevate the standard of medical imaging for the well-being of our valued patients."
        />
        <ServiceV
            name="Ultra Sound Scan"
            url="https://drive.google.com/uc?id=1V1UEnU99Y1n6uGcU7Dtddcc2U16M7dej"
            content="Welcome to our medical center where we proudly offer cutting-edge ultrasound scanning services. At our facility, we understand the importance of precise diagnostics, and our ultrasound scanning service exemplifies our commitment to advanced healthcare. Utilizing the latest technology and led by a team of experienced medical professionals, we provide comprehensive ultrasound examinations with exceptional accuracy. Whether you're seeking prenatal care, cardiovascular assessments, or abdominal imaging, our ultrasound services are tailored to meet diverse medical needs. We prioritize patient comfort and well-being, ensuring a reassuring environment for each examination. Trust in our medical center for unparalleled ultrasound scanning services that prioritize your health and provide invaluable insights for informed medical decisions. Your well-being is our priority, and we're here to deliver excellence in medical care through our advanced ultrasound services."
        />
        <Footer/>
        </>
    )
}

export default Services