import './AboutUsStyles.css'



const AboutUs =()=>{
    return(
        <div className="about-container">
            <h1>Our Story</h1>
            <p>Pioneers in Digital Health  Fully computerized Hospital Mangement Information System established in  2003. Now grown to more than 700000 number of patients database. System integrating all the services OPD ,Channelled Consultation,Modern Laboratory,Digital X Ray ,Ultrasound Scanning,Echocardiography,Pharmacy ,Physiotherapy. 
Our OPD is fully equipped with modern technology in every aspect especially ENT and Eye.</p>

            <h1>Our Vision</h1>
            <p> At our medical center, we envision a future where healthcare transcends boundaries, providing holistic and patient-centric services that empower individuals to lead healthier lives. We strive to be a beacon of medical excellence, pioneering innovative practices, and embracing cutting-edge technologies to deliver compassionate care with unwavering dedication. Our vision is to become a trusted healthcare partner, fostering a community where well-being is a shared priority, and every individual receives personalized, comprehensive, and accessible medical support.</p>

            
            <h1>Our Mission</h1>
            <p>Our mission is to redefine healthcare by delivering exceptional medical services with integrity, empathy, and a commitment to excellence. We aim to provide comprehensive and accessible healthcare services that address the unique needs of each individual. Through a collaborative and multidisciplinary approach, we aspire to prevent, diagnose, and treat illnesses, promoting proactive health management. We prioritize patient education, community engagement, and continuous innovation to ensure that our medical center remains a center of excellence, dedicated to improving the health and quality of life for all those we serve.</p>
        
            
        </div>
    );

    
}

export default AboutUs