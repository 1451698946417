import './heroStyles.css'

function Hero(props) {
    return (
        <>
        <div className={props.cName}>
            <img alt='introd' src={props.content}></img>
            <div className='hero-text'>
                <h1>
                    {props.title}
                </h1>
                <p>{props.para}</p>
            </div>
        </div>
        
        </>
    )
}

export default Hero