import './App.css';
import { Route,Routes,Router } from 'react-router-dom';
import Home from './routes/home';
import Services from './routes/services';
import Doctors from './routes/doctors';
import About from './routes/about';
import Contact from './routes/contact';
import { Helmet } from 'react-helmet';
import logo from './assets/BMS logo.png';


function App() {
  return (
    <div className="App">
        <Helmet>
          <title>BMS</title>
          <meta name="description" content="Baddegama Medical Services" />
          <link rel="logo" href={logo} />
          {/* Add other meta tags as needed */}
        </Helmet>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/doctors' element={<Doctors/>}/>
          <Route path='/aboutUs' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
    </div>
  );
}

export default App;
