import Navbar from "../components/Navbar"
import AboutImg from '../assets/about.jpg'
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import AboutUs from "../components/AboutUs"


function About() {
    return (
        <>
        <Navbar/>
        <Hero
            cName="hero-mid"
            content={AboutImg}
            title="About Us"
        
        />
        <AboutUs/>
        <Footer/>
        </>
    )
}

export default About