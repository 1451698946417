import React from "react";
import Slider from "react-slick";
import { useState, useEffect } from 'react';
import './imageSlider.css'
import opd from '../assets/slider/opd.avif'
import chanel from '../assets/slider/echo.avif'
import lab from '../assets/slider/laboratory.avif'
import physio from '../assets/slider/physio.avif'
import echo from '../assets/slider/echo.avif'
import eye from '../assets/slider/eye.avif'
import xray from '../assets/slider/xray.avif'
import ultrasound from '../assets/slider/ultra sound.avif'

import { Link } from "react-router-dom";

const ImageSlider=()=>{

  const images = [
    {
      url: opd,
      text: 'OPD',
    },
    {
      url: chanel,
      text: 'Chanelling Service',
    },
    {
      url: lab,
      text: 'Laboratory Service',
    },
    {
      url: physio,
      text: 'Physiotherapy',
    },
    {
      url: echo,
      text: 'Echocardiography',
    },
    {
      url: eye,
      text: 'Eye Clinic',
    },
    {
      url: xray,
      text: 'Digital X Ray',
    },
    {
      url: ultrasound,
      text: 'Ultra Sound Scanning',
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current slide index
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 2500); // Change slide every 1.5 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []); // Empty dependency array ensures the effect runs only once
  
      return (
        <>
            <h1 className="xxx">Our Services</h1>
            <div className="slider-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
        >
          <img className="slider-image" src={image.url} alt={`Slide ${index + 1}`} />
          <div className="slide-text">{image.text}</div>
        </div>
      ))}
    </div>

        <div class="center-container">
            <Link to='/services'>
              <button class="center-button">See More</button>
            </Link>
        </div>
        </>
        
      );
}

export default ImageSlider