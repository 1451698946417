import WelcomeImg from '../assets/welcome-img.jpg'
import './welcomeStyles.css'
import { Link } from 'react-router-dom';


const Welcome = () => {
    return (
      <div className="welcome">
        <div className="set">
          <div className="left-column">
            <div className="head">
              <h1>Welcome to Baddegama Medical Services Limited</h1>
            </div>
            <div className="pset">
              <p>
                Pioneers in Digital Health Fully computerized Hospital Management Information System established in 2003. Now grown to more than 700000 numbers of patients database. System integrating all the services OPD, Channeled Consultation, Modern Laboratory, Digital X-Ray, Ultrasound Scanning, Echocardiography, Pharmacy, Physiotherapy. Our OPD is fully equipped with modern technology in every aspect especially ENT and Eye.
              </p>
            </div>
            <div className="bset">
              <Link to="/aboutUs">
                <button>Read More</button>
              </Link>
            </div>
          </div>
          <div className="right-column">
            <div className="image">
              <img src={WelcomeImg} alt="Welcome Image" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  

export default Welcome