import './serviceStyle.css'

function ServiceC(props){
    return(
        <div className="container">
            <h1 className='topic'>{props.name}</h1>
            <div className="content">
                <img className="image" src={props.poto} alt="Image" />
                <p className="text">{props.content}</p>
            </div>
      </div>
    );
}


export default ServiceC;