import './ContactFormStyle.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function ContactForm(){
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rr57awy', 'template_z789yj5', document.getElementById('form1'), 'LbxaXw3Uw5xKW1ylF')
      .then((result) => {
          console.log(result.text);
          alert('Email sent successfully!');
          resetForm();  

      }, (error) => {
          console.log(error.text);
          alert('Error sending email. Please try again.');
      });
  };

  const resetForm = () => {
    document.getElementById('form1').reset();
  };

    return(
        <div className='form-container'>
            <h1>Send a Message to us !</h1>
            <form onSubmit={sendEmail} id='form1'>
                <input placeholder='Name' name='user_name'/>
                <input placeholder='Email' name='user_email'/>
                <input placeholder='Subject'/>
                <textarea placeholder='Message' name='message' rows="4"/>
                <button>Send Message</button>
            </form>
        </div>
    );
}

export default ContactForm;