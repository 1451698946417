import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import ContactForm from "../components/ContactForm"
function Contact() {
    return (
        <>
        <Navbar/>
        <Hero
            cName="hero-mid"
            content="https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg"
            title="Contact Us"
        
        />

        <ContactForm/>

        <Footer/>
        
        </>
    )
}

export default Contact