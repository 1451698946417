import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import Footer from "../components/Footer"
import DoctorsDetails from "../components/DoctorDetails"


function Doctors() {
    return (
        <>
        <Navbar/>
        <Hero
            cName="hero-mid"
            content="https://burst.shopifycdn.com/photos/stethoscope-scrubs.jpg?"
            //content="https://burst.shopifycdn.com/photos/doctors-medical-gear.jpg"
            title="Consultants"
        
        />
        <DoctorsDetails/>
        <Footer/>
        </>
    )
}

export default Doctors