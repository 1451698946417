import './DoctorsStyle.css'
import DoctorData from './DoctorData';
import Doctor1 from '../assets/doctor.png'
import { Link } from 'react-router-dom';


function DoctorsC(){
    return(
        <div className='doctors'>
            <h1 class = "xxx">Our Consultants</h1>
            <p>Where Compassion Meets Expertise: Healing Hands, Caring Hearts.</p>
            <div className='doctor-card'>
                
                <DoctorData
                    image={Doctor1}
                    name="Prof H.M.M. Herath"
                    data="MBBS(Ruhuna), MD(Col), MRCP(UK), FRCP(Edin), FCCP,Consultant Physician and Professor in Medicine"
                />
                <DoctorData
                    image={Doctor1}
                    name="Prof Niroshana Dahanayake"
                    data="MBBS, MD,FCCP, Consultant Physician and Professor in Medicine, Teaching Hospital Karapitiya"
                />
                <DoctorData
                    image={Doctor1}
                    name="Dr. Warsha De Zoysa"
                    data="MBBS, MD(Medicine), Consultant Physician and Senior Lecturer in Medicine,Teaching Hosptial Karapitiya"
                />
            </div>
            <div className="center-container">
                <Link to='doctors'>
                    <button className="center-button">See More</button>
                </Link>
            </div>
        </div>
    );
}

export default DoctorsC