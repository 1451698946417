import './DoctorsStyle.css'


function DoctorData(props) {
    return (
        <div className='d-card'>
            <div className='d-image'>
                <img src={props.image} alt='doctor image'/>
            </div>
            <h4 className='name'>{props.name}</h4>
            <p className='ql'>{props.qualification}</p>
            <h5>{props.data}</h5>
        </div>

    );
}

export default DoctorData